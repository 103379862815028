@import "../../styles/importStyles.scss";

@media (max-width: 450px) {
    .calendar-header, .calendar-text {
        width: 220px !important;
    }
}

.profileContainer{
    padding: 0 8%;

    .pictures-row {
        position: relative;
        #cover-pic {
            background-size: cover;
            $width: 100%;
            width: $width;
            padding-bottom: $width/2.5;
            min-height: 200px;
            &:hover {
                cursor: pointer;
                filter: brightness(0.8);
            }
        }
    
        #profile-pic {
            background-size: cover;
            width: 180px;
            height: 180px;
            border: 4px solid white;
            border-radius: 10px;
            position: absolute;
            bottom: -20px;
            left: 6%;
            box-shadow: 1px 0 4px 1px rgba(92, 92, 92, 0.081);
            &:hover {
                cursor: pointer;
                filter: brightness(0.8);
            }
        }
    }
    
    .info-row {
        padding: 4% 5%;

        .edit-content {
            @include button2;
            width: 100%;
            margin: 5% 0 10% 0;
        }

        .line1 {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            .send-message {
                @include emailButton;
                text-decoration: none;
                margin-top: 10px;
            }
            .name {
                @include subtitle;
                margin: 0;
                .gender {
                    @include text3;
                    margin-left: 10px;
                    letter-spacing: 0;
                }
            }
        }
        
        .roles {
            @include text2;
            margin: 10px 0 15px 0;
        }
        .location {
            display: flex;
            align-items: center;
            @include text2;
            margin: 30px 0;
            img {
                height: 20px;
                margin-right: 5px;
            }
        }
        .metaData {
            display: flex;
            @include text2;
            font-size: 14px;
            p {
                margin: 0; 
                margin-right: 30px;
            } 
            img {
                height: 12px;
            }
        }
        .icons {
            display: flex;
            margin: 25px 0;
            img {
                height: 35px;
                margin-right: 20px;
            }
        }
        .about-header, .working-with, .skills {
            @include heading2;
            font-size: 19px;
            margin: 30px 0 10px 0;
        }
        .text {
            @include text2;
            font-size: 15px;
            margin: 0;
        }
        .skills-list {
            .skill-item {
                @include text2;
                font-size: 15px;
            }
        }
        .list {
            padding: 0;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            .list-item {
                padding: 5px 0;
                margin: 3px 0;
                border: 1px solid $borders;
                border-radius: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                @include text2;
                img {
                    height: 35px;
                    width:  35px;
                    border-radius: 50%;
                    margin: 0 10px;
                }
                span {
                    width: 110px;
                    font-size: 15px;
                    margin: 10px;
                }
                span:last-of-type {
                    width: 190px;
                }
                &:hover {
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, 0.04);
                }
            }
        }
    }

    .identity-list {
        @include text2;
        font-size: 15px;
    }

    .calendar-row {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .calendar-header {
            @include heading2;
            margin-top: 10%;
            width: 372px;
        }
        .calendar-text {
            @include text3;
            width: 372px;
            margin: 0 0 15px 0;
        }
    }

}
