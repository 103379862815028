@import "./Typography.scss";
@import "./Colors.scss";


@mixin mainButtons {
    @include text1;
    height: 40px;
    width: 240px;
    border-radius: 4px;
    border: 2px solid transparent;
    transition: 0.2s;
}

@mixin button1 {
    @include mainButtons;
    color: white;
    background-color: $primary;
    &:hover {
        background-color: $orange;
    }
}
@mixin button2 {
    @include mainButtons;
    color: $primary;
    background-color: transparent;
    border-color: $primary;
    &:hover {
        border-color: $orange;
        color: $orange;
    }
}
@mixin button3 {
    @include mainButtons;
    color: $primary;
    background-color: transparent;
    &:hover {
        color: $orange;
    }
}

@mixin shortBtn1 {
    @include button1;
    width: 100px;
    height: 35px;
}

@mixin shortBtn2 {
    @include button2;
    width: 100px;
    height: 35px;
}

@mixin shortBtn3 {
    @include button3;
    width: 100px;
    height: 25px;
    font-size: 11px
}

@mixin emailButton {
    @include button2;
    font-size: 11px;
    height: 25px;
    width: 110px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        margin-bottom: 1px;
        height: 10px;
        margin-right: 5px;
        filter: invert(19%) sepia(59%) saturate(2002%) hue-rotate(352deg) brightness(79%) contrast(83%);
    }
}

@mixin borderStyle {
    border: 2px solid $borders;
    border-radius: 5px;
    background-color: $inputs;
    outline: none;
    padding-left: 8px;
    height: 37.7px;
}