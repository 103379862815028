@import "../../styles/importStyles.scss";

.project_dashboard_container {
    padding: 5% 15%;
    .project_dashboard_title {
        @include title;
    }
    .project_dashboard_text {
        @include text2;
        margin: 0;
        margin-bottom: 15px;
    }
    .create_new_project {
        @include button1;
        margin: 35px 0 50px;
    }
    .active_projects_heading {
        @include heading2;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 25px;
    }
    .nav-link {
        @include text2;
        color: $primary;
        background-color: $secondary;
        opacity: 0.5;
        &.active {
            background-color: $secondary;
            color: $primary;
            opacity: 1;
        }
    }
    .active_projects_container {
        max-width: 600px;
        @include text3;
        box-shadow: 0 0 5px 1px rgba(96, 96, 96, 0.081);
        border: 2px solid $borders;
        border-radius: 10px;
        padding: 10px 20px 25px 20px;
        background-color: white;
        .project {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 0;
            border-bottom: 1px solid $borders;
            .project_name, .project_role {
                width: 37%;
            }
            .project_name {
                color: $primary;
                letter-spacing: 0.5px;
                font-weight: bold;
            }
            .view_project {
                @include shortBtn2;
                width: 80px;
                height: 30px;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                margin: 10px 0;
            }
        }
        .first_row {
            p {
                @include text2;
                font-weight: bold;
                color: black !important;
            }
        }
    }
}