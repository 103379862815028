@import "../../styles/importStyles.scss";

.createContainer {
    padding: 5%;
    display: flex;
    justify-content: center;
    .editContainer {
        margin-top: 0;
    }
    .create-form {
        width: 100%;
        max-width: 600px;
        h1 {
            @include title;
        }
        .error-message {
            @include text2;
            color: red;
            margin: 10px;
        } 
        .inputs {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .roleInput{
                width: 100%;
            }
            margin-bottom: 10px;
        }
        .phoneInput-div {

            .phoneInput {
                width: fit-content;
                position: relative;
                .react-tel-input {
                    @include text2;
                    .form-control {
                        height: 37.7px;
                        input {
                            padding: 8px;
                        }
                        
                    }
                }

                .closeButton {
                    position: absolute;
                    top: 7px;
                    right: 10px;
                    color: $primary;
                    height: 25px;
                    width: auto;
                    &:hover {
                        cursor: pointer;
                    }
                } 
            }
        }
        .save[disabled] {
            background-color: #c19090;
        }
    }
    .selectors {
        position: relative;
        .section-selector {
            border-radius: 50%;
            background-color: $orange;
            height: 20px;
            width: 20px;
            margin: 10px 40px 20px 0;
        }
        .active {
            background-color: $secondary;
        }
    }
}
