.editContainer {
    .gender-selection {
        margin: 20px 0;
    }
    .lgbtqia2 {
        margin-bottom: 25px;
    }
    .ethnicity {
        margin-bottom: 0;
    }
}