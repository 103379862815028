@import "../../styles/importStyles.scss";

.find-crew-home-page {
    z-index: 1;
    margin-left: 15%;
}

.find-crew-heading {
    @include heading1;
    font-weight: bold;
    letter-spacing: normal;
    text-transform: none;
    font-size: 22px;
    margin-top: 5%;
}

.home-filter-form {
    display: flex;
    flex-wrap: wrap;
    max-width: 920px;
    margin-bottom: 2.5%;

    .select-position, .select-location {
        margin-top: 2%;
        margin-right: 15px;
        
        .inputs {
            display: flex;
            align-items: center;
            position: relative;

            label {
                @include text3;
            }

            .roleInput, .cityInput {
                .input-icon {
                    position: absolute;
                    height: 15px;
                    bottom: 12px;
                    left: 10px;
                    z-index: 1;
                }

                fieldset {
                    border: none;
                }

                input {
                    border: 2px solid $borders;
                    border-radius: 5px;
                    @include text3;
                    padding-left: 30px;
                    background-color: $inputs;
                }

                div div div {
                    padding: 0;
                    border-radius: 5px;
                }

                .MuiAutocomplete-popper div {
                    padding-left: 8px;
                }

                label {
                    all: initial;
                    padding-bottom: 5px;
                    @include text2;
                    font-size: 18px;
                    color: white;
                }
            }
        }
    }
    .availability {
        max-width: 250px;
        margin-top: 1.9%;
        margin-right: 20px;
        .availability-label {
            all: initial;
            margin-bottom: 5px;
            @include text2;
            font-size: 18px;
            color: white;
        }
        .calendar {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin: 0;
            z-index: 3;
            width: 100%;
            margin-top: 1px;
            .calendar1, .calendar2 {
                width: 46%;
            }
            p {
                margin: 5px 6px;
            }
            input {
                height: 38px;
                width: 100%;
                padding: 8px;
                @include text3;
            }
        }
    }
    .search {
        @include shortBtn1;
        margin-top: 20px;
        height: 37.7px;
        align-self: flex-end;
    }
}

.most-researched {
    .searches {
        @include text2;
        font-style: italic;
        margin-top: 35px;
    }
    .search-links {
        max-width: 920px;
        display: flex;
        flex-wrap: wrap;
        .search-link {
            @include text2;
            font-weight: bold;
            color: $primary;
            text-decoration: none;
            margin: 10px 30px 10px 0;
            &:hover {
                color: $orange;
            }
        }
    }
}