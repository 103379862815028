@import "../../styles/importStyles.scss";
.cropContainer {
    height: 60vh;
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .cropper {
        height: 80%;
        width: 80%;
        position: relative;
    }
    .sliders {
        width: 100%;
        .slider {
            display: flex;
            align-items: center;
        }
        .slider-text {
            @include text2;
            margin: 10px;
            margin-right: 20px;
        }
    }
    .save {
        @include button1;
    }
    .profile-pic, .cover-pic {
        background-size: cover;
        $width: 60%;
        width: $width;
        padding-bottom: $width;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    .edit {
        position: relative;
        margin-top: 5%;
        input {
            position: absolute;
            height: 40px;
            width: 150px;
            left: 0;
            top: 0;
            opacity: 0;
            &:hover {
                cursor: pointer;
            }
        }
        p {
            color: $primary;
            border: 3px solid $primary;
            border-radius: 5px;
            padding: 3px;
            margin: 0;
            @include heading2;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 10px;
            .icon { 
                height: 16px;
                width: 16px;
                margin-right: 5px;
            }
        }
        &:hover {
            cursor: pointer;
        }
    }

    .cover-pic {
        $width: 100%;
        width: $width;
        padding-bottom: $width/2.5;
    }
    
    .close {
        align-self: flex-end;
        margin: 0 15px 15px 15px;
        color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        border: 2px solid $primary;
        border-radius: 5px;
        padding: 8px;
        &:hover {
            cursor: pointer;
        }
    }
}
