@import "../../styles/importStyles.scss";


@media (max-width: 768px) {
    .settings-options {
        border: none !important;
        border-bottom: 1px solid $borders !important;
        padding: 10px 0 !important;
        height: fit-content !important;
    }
    .settings-content {
        padding: 30px 10px !important
    }
} 

@media (max-width: 992px) {
    .settings-container {
        max-width: 960px !important;
    }
}

.settings-container {
    @include text2;
    padding: 5% 10%;

    .title {
        @include title;
        padding: 0;
    }

    .heading {
        @include heading2;
        padding: 0;
    }
    .subtitle {
        @include subtitle;
        font-size: 20px;
        margin-bottom: 25px;
    }

    .settings-box {
        padding: 20px;
        border: 1px solid $borders;
        border-radius: 10px;
        box-shadow: 0 0 5px 1px rgba(96, 96, 96, 0.081);
        width: 100%;
        max-width: 750px;
        min-height: 450px;
        margin: 2% 0;

        .setting-row {
            width: 100%;
            height: 100%;
            .settings-options {
                display: flex;
                flex-direction: column;
                border-right: 1px solid $borders;
                padding-right: 10px;
                padding-top: 10px;
                height: 100%;
                .active {
                    color: $orange;
                }
                p:hover {
                    cursor: pointer;
                }
            }

            .settings-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 20px 60px;
                label {
                    padding: 0;
                    margin: 10px 0 5px 0;
                }
                input {
                    height: 37.7px;
                    width: 100%;
                    max-width: 510px;
                }

                .email-input {
                    padding: 8px;
                }

                .phoneInput {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    padding: 0;
                    width: 100%;
                    .react-tel-input {
                        @include text2;
                        .form-control {
                            height: 37.7px;
                            input {
                                padding: 8px;
                            }
                            
                        }
                    }
                    
                    .closeButton {
                        position: absolute;
                        right: 10px;
                        color: $primary;
                        height: 25px;
                        width: auto;
                        &:hover {
                            cursor: pointer;
                        }
                    } 
                }
                .buttons {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;
                    margin-bottom: 30px;
                    .save {
                        @include shortBtn1;
                        height: 37.7px;
                        margin-left: 10px;
                    }
                    .save[disabled] {
                        background-color: #c19090
                    }
                }
            }
        }
    }
}

.small-loader {
    margin: 5% 50%;
    color: $primary;
} 