@import "../../styles/importStyles.scss";

@media (max-width: 1240px) {
    .crewCard {
        .roles {
            max-width: 200px !important;
        }
    }
  }

.crewCard {
    margin: 5px 0;
    padding: 10px 0;
    min-height: 150px;
    width:  100%;
    border: 1px solid $borders;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0 0 5px 1px rgba(96, 96, 96, 0.081);
    background-color: white;
    color: $dark;
    text-decoration: none;
    transition: 0.3s;
    &:hover {
        border: 2px solid $blue;
        cursor: pointer;
        color: $dark;
    }

    .row1 {
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        padding-bottom: 0;
        .name {
            @include heading2;
            font-size: 20px;
            letter-spacing: 1.5px;
            // font-weight: bold;
        }
        .see-profile {
            @include shortBtn2;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
        }
    }
   
    .row2 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .crewCard-info {
            display: flex;
            align-items: flex-start;
            .profile-picture {
                height: 75px;
                width: 75px;
                border-radius: 10px;
                margin: 0 12px;
            }
            .text {
                @include text3;
                display: flex;
                flex-direction: column;
                margin-right: 12px;
                .roles {
                    margin: 2px 0 5px 0;
                    overflow-y: auto;
                    max-width: 380px;
                }
                .location {
                    margin: 5px 0;
                    display: flex;
                    align-items: center;
                    img {
                        height: 15px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .metaData {
            margin: 15px;
            margin-bottom: 0;
            @include text3;
            font-size: 12px;
            min-width: 190px;
            p {
                font-weight: bold;
                margin: 0;
                img {
                    height: 8px;
                }
            }
        }
    }
    
}



