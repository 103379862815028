@import "../../styles/importStyles.scss";

@media (max-width: 500px) {
    .project_data {
        img {
            width: 120px !important; 
            height: 120px !important;
        }
        .project_data_element {
        
            font-size: 14px !important;
        }
    }
  }

.project_container {

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    ::-webkit-scrollbar-track:hover {
        background: $inputs;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(180, 180, 180, 0.5);
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(180, 180, 180, 0.7);
    } 

    position: relative;
    padding: 20px 5% 0 10%;
    width: 100%;
    // min-height: calc(100vh - 60px);
    display: flex;
    justify-content: space-between;
    .project_details {
        max-width: 780px;
        height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .pic_and_info {
            position: relative;
            overflow-y: auto;
            max-height: 45%;
            .pic_row {
                display: flex;
                align-items: flex-start;
                width: 100%;
            }
            #project-pic {
                background-size: cover;
                margin-right: 20px;
                min-width: 200px;
                height: 200px;
                border: 4px solid white;
                border-radius: 10px;
                box-shadow: 1px 0 4px 1px rgba(92, 92, 92, 0.081);
                margin-bottom: 5px;
                &:hover {
                    cursor: pointer;
                    filter: brightness(0.8);
                }
            }
            .project_title {
                @include title;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                width: 100%;
                background-color: $background;
                h1 {
                    white-space: nowrap;
                    font-size: 2.2rem;
                    overflow-x: auto;
                    margin: 0;
                }
                .icon {
                    font-size: 38px;
                    margin-bottom: .5rem;
                    color: $primary;
                    margin: 0;
                    &:hover {
                        cursor: pointer;
                        color: $orange;
                    }
                }
                .edit_settings {
                    height: 20px;
                    width: 20px;
                    margin: 10px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .project_data {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                margin: 0;
                padding: 0 50px 0 0;
                width: calc(100% - 20px);
                height: 200px;
                overflow-y: auto;
                .data {
                    position: relative;
                    @include text2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    label {
                        @include text2;
                    }
                    .project_data_element {
                        margin: 0 0 5px 0;
                        span {
                            font-weight: bold;
                        }
                    }
                    .description {
                        margin-top: 10px;
                        .see_more {
                            color: $primary;
                            font-size: 14px;
                            &:hover {
                                cursor: pointer;
                                color: $orange;
                            }
                        }
                    }
                    .edit_producers {
                        @include borderStyle;
                        @include text2;
                        height: 38px;
                        width: 100%;
                    }
                    .edit_location {
                        width: 100%;
                        margin: 0;
                        margin-bottom: 24px;
                        height: 38px;
                        ::-webkit-input-placeholder {
                            color: black;
                            opacity: 0.65;
                        }
                        :-moz-placeholder {
                            color: black;
                        opacity: 0.65;
                        }
                        ::-moz-placeholder {
                            color: black;
                        opacity: 0.65;
                        }
                        :-ms-input-placeholder {
                            color: black;
                            opacity: 0.65;
                        }
                        ::-ms-input-placeholder { 
                            color: black;
                            opacity: 0.65;
                        }
                        ::placeholder {
                        color: black;
                        opacity: 0.65;
                        }
                        fieldset {
                            border: none;
                        }
                        input {
                            border: 2px solid $borders;
                            border-radius: 5px;
                            height: 19px;
                            @include text2;
                        }
                        div div div {
                            background-color: $inputs;
                            padding: 0;
                        }
                        label {
                            all: initial;
                            padding-bottom: 5px;
                            @include text2;
                        }
                    }
                    .edit_dates {
                        width: 100%;
                        label {
                            @include text2;
                        }
                        .calendar {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            @include text2;
                            height: 38px;
                            .calendar1, .calendar2 {
                                width: 45%;
                            }
                            input {
                                height: 38px;
                                padding: 8px;
                                width: 100%;
                            }
                        }
                    }
                    .edit_description {
                        @include borderStyle;
                        height: 100px;
                        width: 100%;
                    }
                    .cancel_button {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 20px;
                        color: $primary;
                        margin-left: auto;
                        &:hover {
                            color: $orange;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .project_members_container {
            border: 2px solid $borders;
            border-radius: 10px;
            padding: 20px;
            padding-right: 10px;
            // min-height: 200px;
            height: 55%;
            width: 90%;
            z-index: 1;
            .project_members_heading {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
                h2 {
                    @include text2;
                    font-weight: bold;
                    margin: 0;
                }
                .invite_member {
                    color: $primary;
                    @include text3;
                    font-weight: bold;
                    margin: 0;
                    margin-right: 10px;
                    &:hover {
                        cursor: pointer;
                        color: $orange;
                    }
                }
                .privacy_setting {
                    @include text3;
                    color: $primary;
                    font-weight: bold;
                    &:hover {
                        cursor: pointer;
                        color: $orange;
                    }
                }
            }
            .project_members_list {
                padding: 15px 0 5px 0;
                max-height: calc(100% - 21.6px);
                overflow-y: auto;
                list-style: none;
                padding: 0;
                @include text3;
                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 4px;
                    margin-right: 10px;
                    border-bottom: 1px solid $borders;
                    width: calc(100% - 10px);
                    min-height: 65px;
                    .bold {
                        font-weight: bold;
                        max-width: 130px;
                        width: 100%;
                    }
                    input {
                        @include borderStyle;
                        max-width: 130px;
                    }
                    span {
                        padding: 5px;
                        max-width: 250px;
                        width: 100%;
                    }
                    .pending {
                        width: 100%;
                        max-width: 180px;
                    }
                    .contact_info {
                        display: flex;
                        flex-direction: column;
                        font-size: 11px;
                        width: 100%;
                        max-width: 180px;
                        position: relative;
                        .close_icon {
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 15px;
                            color: $primary;
                            &:hover {
                                cursor: pointer;
                                color: $orange;
                            }
                        }
                    }
                    .contact_button {
                        @include shortBtn3;
                        min-width: 180px;
                        font-size: 13px;
                        height: 30px;
                        margin: 2px;
                        text-align: left;
                    }
                    .contact_button[disabled] {
                        color: rgb(215, 198, 198);
                    }
                    .remove_button {
                        font-size: 16px;
                        color: $primary;
                        &:hover {
                            color: $orange;
                            cursor: pointer;
                        }
                    }
                    .delete_button {
                        width: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .edit_icon_div {
                        width: 115px;
                        text-align: right;
                        .edit_icon {
                            color: $primary;
                            height: 18px;   
                            &:hover {
                                color: $orange;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
    .edit_buttons {
        position: absolute;
        top: 0;
        left: 35%;
        .save_button {
            @include shortBtn1;
            width: 80px;
            height: 30px;
            margin: 5px;
        }
        .cancel_button {
            @include shortBtn2;
            width: 80px;
            height: 30px;
            margin: 5px;
        }
    }
    .project_chatbox {
        margin: 10px auto 15px auto;
        // max-width: 550px;
        width: 95%;
        height: calc(100vh - 105px);
        max-height: 800px;
        background-color: white;
        border: 2px solid $borders;
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ::-webkit-scrollbar {
            width: 5px;
        }
        ::-webkit-scrollbar-track {
            background: $inputs;
        }
        ::-webkit-scrollbar-thumb {
            background: rgba(180, 180, 180, 0.5);
        }
        ::-webkit-scrollbar-thumb:hover {
            background: rgba(180, 180, 180, 0.7);
        } 
        .messages {
            height: 100%;
        }
    }
}


.privacy-popper {
    display: flex;
    align-items: center;
    label {
        margin-left: 5px;
    }
    .mfa-switch {
        transform: scale(0.8)
    }
}

.edit_popper {
    @include text3;
    color: $primary;
    background-color: #efefef;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 10px;
    z-index: 1;
    min-width: 200px;
    .edit_button {
        display: flex;
        align-items: center;
        .edit_icon {
            height: 15px;
        }
        &:hover {
            cursor: pointer;
            color: $orange;
        }
    }
    .delete_button {
        display: flex;
        align-items: center;
        .delete_icon {
            height: 15px; 
        }
        &:hover {
            cursor: pointer;
            color: $orange;
        }
    }
}