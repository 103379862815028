@import "../../styles/importStyles.scss";

@media (max-width: 768px) {
    .filter-row {
        height: fit-content !important;
        .filter {
            height: fit-content !important;
            min-height: fit-content !important;
        }
    }
}

@media (max-width: 1230px) {
    .filter {
        .checkbox {
            label {
                font-size: 13px !important;
            }
        }
    }
}

.filter-container {
    position: relative;
    .filter-row {
        .filter {
            background-color: $secondary;
            padding: 0 5%;
            // min-height: calc(100vh - 60px);
            height: calc(100vh - 60px);
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgb(173, 173, 173);
            } 
            .form {
                display: flex;
                flex-direction: column;
                    
                .heading {
                    @include heading2;
                    margin-top: 15px;
                }
                .union_tag_label {
                    margin-top: 25px;
                }
                .union-specific-radio {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    .option {
                        display: flex;
                        align-items: center;
                        input {
                            margin-right: 5px;
                        }
                        label {
                            @include text3;
                        }
                    }
                }
                .select-position {
                    margin-top: 5px;
                    
                    .inputs {
                        display: flex;
                        align-items: center;

                        label {
                            @include text3;
                        }

                        .roleInput {
                            width: 100%;
                            fieldset {
                                border: none;
                            }

                            input {
                                border: 2px solid $borders;
                                border-radius: 5px;
                                @include text3;
                                background-color: $inputs;
                            }

                            div div div {
                                padding: 0;
                            }

                            .MuiAutocomplete-popper div {
                                padding-left: 8px;
                            }

                            label {
                                all: initial;
                                padding-bottom: 5px;
                                @include text3;
                            }
                        }
                    }
                    .roles-error {
                        @include text2;
                        width: 100%;
                        margin: 0;
                        color: red;
                    }

                }
                .select-location {
                    .inputs {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-end;
                        justify-content: space-between;
                        margin: 0;
                        .cityInput {
                            margin: 0;
                            width: 73%;
                            fieldset {
                                border: none;
                            }
                            input {
                                border: 2px solid $borders;
                                border-radius: 5px;
                                @include text3;
                            }
                            div div div {
                                background-color: $inputs;
                                padding: 0;
                            }
                            label {
                                all: initial;
                                padding-bottom: 5px;
                                @include text3;
                            }
                        }
                        .radiusInput {
                            display: flex;
                            flex-direction: column;
                            margin: 0;
                            width: 25%;
                            position: relative;
                            label {
                                @include text3;
                                padding-bottom: 2px;
                            }
                            .radius-arrow {
                                position: absolute;
                                top: 28px;
                                right: 2px;
                                pointer-events: none;
                                color: rgb(109, 109, 109);
                            }
                            #radius {
                                -webkit-appearance:none;
                                appearance:none;
                                height: 38px;
                                background-color: $inputs;
                                border: 2px solid $borders;
                                border-radius: 5px;
                                padding: 8px;
                                @include text3;
                                outline: none;
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .availability {
                    display: flex;
                    flex-direction: column;
                    .calendar {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        margin: 0;
                        z-index: 3;
                        width: 100%;
                        .calendar1, .calendar2 {
                            width: 45%;
                        }
                        p {
                            margin: 5px;
                        }
                        input {
                            height: 37.7px;
                            width: 100%;
                            padding: 8px;
                        }
                    }
                }
                .search {
                    @include button1;
                    width: 100%;
                    margin: 20px 0 10px 0;
                }
                .meta-data {
                    margin: 20px 0 0 0;
                    .checkbox {
                        display: flex;
                        align-items: flex-start;
                        input {
                            margin: 4px 0; 
                        }
                        label {
                            @include text2;
                            margin-left: 5px;
                            width: 90%;
                        }
                    }
                }
                .crew-name {
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    width: 100%;
                    position: relative;
                    input {
                        height: 37.7px;
                        background-color: $inputs;
                        border: 2px solid $borders;
                        border-radius: 5px;
                        @include text2;
                        padding: 8px;
                    }
                    .input-icon {
                        position: absolute;
                        height: 15px;
                        bottom: 12px;
                        right: 10px;
                        z-index: 1;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .open_advanced_filters {
                    @include heading2;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    p {
                        margin: 0;
                    }
                    .icon {
                        font-size: 25px;
                    }
                    &:hover {
                        cursor: pointer;
                        color: $orange;
                    }
                }

                .advanced_filters {
                    padding: 10px 0;
                    .checkbox {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 10px;
                        input {
                            margin: 4px 0; 
                        }
                        label {
                            @include text2;
                            margin-left: 5px;
                            width: 90%;
                        }
                    }
                    .ethnicity-selection, .gender-selection {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        .heading {
                            margin: 0;
                            margin-top: 5px;
                            span {
                                @include text3;
                                text-decoration: underline;
                                float: right;
                                &:hover {
                                    color: $orange;
                                    cursor: pointer;
                                }
                            }
                        }
                        .radius-arrow {
                            position: absolute;
                            top: 38px;
                            right: 2px;
                            pointer-events: none;
                            color: rgb(109, 109, 109);
                        }
                        #ethnicity-select, #gender-select  {
                            -webkit-appearance:none;
                            appearance:none;
                            @include borderStyle;
                            width: 100%;
                            margin: 5px 0; 
                            color: grey;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

        }
        .tags {
            padding: 15px 0;
            display: flex;
            flex-wrap: wrap;
            .filter-tag {
                border-radius: 5px;
                border: 1px solid $primary;
                width: fit-content;
                margin: 5px;
                display: flex;
                align-items: center;
                height: 35px;
                p {
                    margin: 5px;
                }
                .close-icon:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
    