@import "../../styles/importStyles.scss";

.go_home {
    @include shortBtn1;
    margin: 15px;
}

.unsubscribe_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 15%;
    .title {
        @include title;
        width: 100%
    }
    .greeting {
        @include heading2;
        width: 100%;
    }
    .text {
        @include text2;
        margin: 0;
        width: 100%;
    }
    .unsubscribe_form {
        max-width: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $borders;
        padding: 30px;
        border-radius: 10px;
        margin-top: 20px;
        .heading {
            @include text2;
            margin: 0;
            margin-bottom: 20px;
            width: 100%;
        }
        .unsubscribe_checkbox {
            display: flex;
            align-items: flex-start;
            width: 100%;
            .email_checkbox {
                margin: 7px 5px 0 25px;
            }
            label {
                .label_description {
                    @include text3;
                }
                p {
                    margin: 0;
                    margin-bottom: 5px;
                }
            }
        }
        .unsubscribe_button {
            @include button1;
            margin: 50px 0 20px 0;   
        }
    }
    
    
}