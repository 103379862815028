@import "../../styles/importStyles.scss";

.job_card {
    margin: 5px 0;
    padding: 10px 15px;
    min-height: 130px;
    width:  100%;
    border: 1px solid $borders;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0 0 5px 1px rgba(96, 96, 96, 0.081);
    background-color: white;
    color: $dark;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
        border: 2px solid $blue;
        cursor: pointer;
        color: $dark;
    }

    h5 {
        @include text2;
        width: 100%;
        margin: 0;
        font-weight: bold;
        padding-left: 2px;
    }

    .job_card_content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 2px;

        .job_info {

            @include text3;
            display: flex;
            flex-direction: column;
            width: 380px;

            .production_title {
                margin: 0;
                font-size: 15px;
                margin-bottom: 10px;
                width: 100%;
            }
        
            .location {
                margin: 3px 0;
                display: flex;
                align-items: center;
                font-size: 12px;
                img {
                    height: 15px;
                    margin-right: 5px;
                }
            }
        }
       
        .metaData {
            margin: 12px;
            font-size: 12px;
            width: 180px;
            p {
                margin: 0;
                img {
                    height: 8px;
                }
            }
        }
        .created-at {
            margin: 0;
            font-size: 12px;
        }
    }
    
}