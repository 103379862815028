@import "./styles/importStyles.scss";

#root {
  background-color: $background;
  min-height: 100vh;
}

.required, .css-wgai2y-MuiFormLabel-asterisk {
  color: #e8370d;
  font-weight: bold;
}

.label-with-tip {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  p {
      margin: 0;
  }
  .tip-icon {
      cursor: pointer;
  }
}

.MuiAutocomplete-popper li {
  @include text2;
}

@media (max-width: 768px) {
  .title {
    font-size: 30px !important;
  }
}