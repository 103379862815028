@import "../../styles/importStyles.scss";

.editContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    input {
        padding: 8px;
        @include text2;
    }

    .buttons, .buttons2 {
        .cancel {
            @include shortBtn2;
            margin-right: 15px;
        }
        .save {
            padding: 0;
            @include shortBtn1;
        }
    }

    .buttons2 {
        width: fit-content;
        margin-top: 20px;
        align-self: flex-end;
    }

    .name-inputs {
        display: flex;
        justify-content: space-between;
        input {
            width: 49%
        }
        height: 37.7px;
    }

    .header, label {
        @include text2;
        margin-top: 20px;
    }

    .roleInput, .cityInput {
        
        fieldset {
            border: none;
        }

        input {
            border-radius: 5px;
            @include text3;
        }

        div div div {
            background-color: $inputs;
            padding: 0;
        }

        .MuiAutocomplete-popper div {
            background-color: white;
            padding-left: 8px;
        }

        .MuiOutlinedInput-root {
            border: 2px solid $borders;
        }

        label {
            all: initial;
            padding-bottom: 5px;
            @include text3;
            margin-top: 5px;
        }
    }
    .cityInput {
        position: relative;
        img {
            position: absolute;
            top: 22px;
            right: 0;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .inputContainer {
        .label {
            @include text3;
            margin-top: 10px;
        }
        .inputWrapper {
            width: 100%;
            background-color: $inputs;
            min-height: 37.7px;
            border: 2px solid $borders;
            .tag {
                background-color: white;
            }
            input {
                background-color: $inputs;
            }
        }
    }

    .roles-error {
        @include text2;
        width: 100%;
        text-align: end;
        padding-right: 20px;
        color: red;
    }

    .cityInput {
        label {
            @include text2;
            margin-top: 20px;
        }
       
    }

    .checkbox {
        input {
            margin-right: 5px;
        }
        
    }

    textarea {
        background-color: $inputs;
        height: 150px;
        border: 2px solid $borders;
        border-radius: 5px;
        @include text2;
        outline: none;
    }

    .metainput {
        height: 37.7px;
    }

    .connections {
        display: flex;
        align-items: center;
        input {
            width: 90%;
            height: 37.7px;
        }
        svg {
            color: $orange;
            margin-left: 20px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .add-row {
        width: fit-content;
        text-align: right;
        margin-left: auto;
    }
    .add-row, .add-skill {
        color: $primary;
        font-weight: 600;
        &:hover {
            cursor: pointer;
            color: $orange;
        }
    }

    .skillsInput, .connectionsInput {
        display: flex;
        justify-content: space-between;
        input {
            width: 70%;
            height: 37.7px;
        }
    }
    .skillTags, .connectionTags {
        display: flex;
        flex-wrap: wrap;
        .skill, .connection {
            background-color: white;
            display: flex;
            align-items: center;
            border: 2px solid $borders;
            border-radius: 5px;
            width: fit-content;
            margin: 5px 5px 5px 0;
            @include text2;
            font-size: 15px;
            p {
                margin: 5px;
            }
            svg {
                color: $orange;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .errorMessage {
        @include text2;
        margin: 0;
        margin-top: 15px;
        color: red;
    }

    .error-message {
        @include text2;
        color: red;
        margin: 10px;
    }

    .ethics-form {
        label {
            margin-top: 0;
        }
        h5 {
            margin-top: 0;
        }
    }
    
}
