@import "../../styles/importStyles.scss";

.invitation-to-join {
    max-width: 350px;
    position: relative;
    top: 10px;
    right: 50px;
    padding: 8%;
    border-radius: 10px;
    border: 2px solid $borders;
    background-color: white;
    box-shadow: 0 0 5px 1px rgba(96, 96, 96, 0.081);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include text3;
    .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $primary;
        height: 20px;
        &:hover {
            cursor: pointer;
        }
    }
    p {
        text-align: center;
        width: 80%;
        margin: 0;
        span {
            color: $primary;
        }
    }
    button {
        @include button1;
        width: fit-content;
        font-size: 16px;
        padding: 4px 8px;
        margin-top: 20px;
    }
}