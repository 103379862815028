@import "../../styles/importStyles.scss";

.policy {
    padding: 5% 10%;
    @include text2;
    color: rgb(73, 73, 73);
    h1 {
        color: $dark;
        @include title;
        margin: 30px 0;
    }
    h2 {
        color: $dark;
        @include heading1;
        margin: 30px 0;
    }
    a {
        text-decoration: none;
        color: rgb(66, 108, 206);
    }
    p {
        margin: 0;
    }
}