@import "../../styles/importStyles.scss";

@media (max-width: 400px) {
    .homeContainer {
        .welcome-col {
            .heading {
                font-size: 22px !important;
            }
            .text {
                font-size: 15px !important;
            }
            .findBtn {
                margin-right: 0 !important;
            }
        }
        .how-row {
            .text {
                font-size: 15px !important;
            }
        }
        .why-box  {
            .heading {
                font-size: 22px !important;
            }
        }
        .testimonial {
            p {
                font-size: 13px !important;
            }
            
        }
    }
}

@media (max-width: 768px) {
    .homeContainer {
        .welcome-col {
            .title {
                font-size: 35px !important;
            }
        }
        .subtitle {
            font-size: 25px !important;
        } 
        .how-images {
            flex-direction: column !important;
            justify-content: center;
        }
    }
}

@media (max-width: 900px) {
    .how-col {
        img {
            padding: 0 !important;
        }
    }
}

@media (max-width: 992px) {
    .welcome-row  {
        opacity: 1 !important;
    }
}

.homeContainer {
    background-color: $background;

    .welcome-row {
        min-height: 750px;
        padding: 0;
        color: white;
        margin-bottom: 8%;
        
        .welcome-col {
            position: relative;
            width: 100%;
            display: flex;
            padding-left: 0;
            flex-direction: column;
            justify-content: center;
            background-color: rgba(216, 34, 10, 0.818);
            .bg-image {
                object-fit: cover;
                position: absolute;
                padding: 0;
                height: 100%; 
                width: 100%;
                transition: 1s;
            }
            .title {
                @include title;
                font-size: 50px;
                max-width: 700px;
                text-transform: none;
                margin-top: 7%;
                z-index: 1;
                margin-left: 15%;
            }
            .heading {

                @include heading1;
                width: fit-content;
                padding-right: 5%;
                z-index: 1;
                margin-left: 15%;
            }
            .text {
                z-index: 1;
                @include text1;
                font-size: 19px;
                max-width: 630px;
                padding: 2% 5% 0 0;
                margin: 0;
                margin-left: 15%;

            }
            .create-profile {
                @include text1;
                color: white;
                z-index: 1;
                margin-left: 15%;
                margin-bottom: 6%;
                width: fit-content;
            }

        }
        
    }

    .one-platform {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0 14% 0;
        img {
            width: 150px;
        }
        .title {
            @include title;
            font-size: 48px;
            text-align: center;
            padding-top: 60px;
        }
    }

    .why-row {
        padding: 0% 15% 10% 15%;
        min-height: 230px;
        .subtitle {
            @include title;
            padding: 4% 0;
        }
        .why-box {
            padding: 10px;
            .boxContent {
                height: 100%;
                padding: 30px;
                border-radius: 10px;
                box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.112);
                background-color: white;
                .heading {
                    @include heading1;
                    color: $blue;
                    margin-top: 10px;
                    margin-bottom: 30px;
                }
                .text {
                    @include text2;
                    margin: 0;
                }

            }

        }

    }

    .how-row {
        padding: 0% 15% 6% 15%;
        .how-col {
            width: 100%;
            padding: 0;
            .subtitle {
                @include title;
                width: 100%
            }
            .text {
                width: 100%;
                @include text1;
                margin-bottom: 60px;
            }
            .how-img {
                padding: 0 50px;
                box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.122);
                border-radius: 10px;
                margin-bottom: 90px;
                background-color: white;
                position: relative;
                transition: 0.5s !important;
                .how-title {
                    @include heading1;
                    font-size: 21px;
                    padding-top: 35px;
                    font-weight: bold;
                }
                .how-images {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-around;
                    padding: 40px 0;
                    .how-element {
                        text-align: center;
                        img {
                            height: 110px;
                        }
                        p {
                            @include text2;
                            max-width: 180px;
                        }
                    }
                    .arrow {
                        width: 30px;
                        margin: 30px 0;
                    }
                }
            }
            
        }

    }

    .testimonials-row {
        padding: 0 15% 10% 15%;
        .testimonials-col {
            padding: 0;
            .subtitle {
                @include title;
                width: 100%;
                padding: 4% 0;
            }
            .testimonial-carousel {
                margin-top: 10px;
                min-height: 300px;
                width: 100%;
                border-radius: 10px;
                box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.122);
                .carousel-indicators {
                    filter: invert(20%) sepia(90%) saturate(1545%) hue-rotate(347deg) brightness(92%) contrast(98%);
                }
                .carousel-control-next-icon, .carousel-control-prev-icon {
                    filter: invert(80%) sepia(90%) saturate(1545%) hue-rotate(347deg) brightness(92%) contrast(98%) !important;
                }
                
                .carousel-item {
                    .testimonial {
                        min-height: 300px;
                        width: 100%;
                        background-color: white;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        p {
                            @include text2;
                            width: 60%;
                            text-align: center;
                            font-style: italic;
                        }
                        .testimonial-name {
                            font-style: normal;
                            span {
                                font-weight: bold;
                            }
                        }
                    }
                }
                
            }
        }
    }
    
    .our-partners {
        padding: 0% 15% 8% 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $secondary;
        .subtitle {
            @include title;
            width: fit-content;
            padding: 4% 0;
        }
        .partner_logos {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;
            padding: 0 10%;
            img {
                max-width: 330px;
                max-height: 230px;
            }
        }
    }
}

.homeContainer .animated-row {
    opacity: 0.1;
    position: relative;
    bottom: -120px;
    transition: transform 0.5s, opacity 0.8s ;
}

.other-animations {
    opacity: 0.1;
    transition: 0.8s;
}

.animated-img {
    opacity: 0.1;
    transition: 0.8s;
}


.fadein {
    opacity: 1 !important;
}

.translate {
    transform: translateY(-120px);
}
