@import "../../styles/importStyles.scss";

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    img {
        height: 150px;
        margin: 5%;
    }
    .progress {
        width: 40%;
        height: 5px;
        background-color: $secondary;
        margin: 50px;
        span {
            background-color: $primary;
        }
    }
}