@import "../../styles/importStyles.scss";

@media (max-width: 768px) {
    .crewInfo-row {
        height: fit-content !important;
    }
}

.crewInfo {
    height: calc(100vh - 60px);
    padding: 0 5% 0 3%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(173, 173, 173);
    } 
    .preview-col {    
        display: flex;
        flex-direction: column;
        align-items: center;
        .sort {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @include text3;
            color: #707070;
            font-style: italic;
            p {
                margin: 5px;
                img {
                    height: 9px;
                    margin: 5px;
                }
            } 
            .open-popper {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .info-col {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
}


.no-match {
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
        @include heading2;
        width: fit-content;
    }
}

.popper {
    position: relative;
    top: 10px;
    right: 40px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid $borders;
    background-color: white;
    z-index: 3;
    p {
        padding: 5px;
        border-radius: 5px;
        &:hover {
            cursor: pointer;
        }
    }
    .active-sort {
        background-color: $secondary;
    }
}
#simple-popper {
    z-index: 4;
}
.popper-definitions {
    position: relative;
    @include text3;
    top: 5px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid $borders;
    background-color: white;
    max-width: 500px;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    font-size: 11px;
    span {
        font-weight: bold;
    }
}

.pagination {
    padding: 10px;
    display: flex;
    justify-content: center;
    .page-link {
        color: black;
    }
    .active .page-link {
        background-color: $primary;
        border-color: $primary;
        color: white;
    }
}
