@import "../../styles/importStyles.scss";

@media (max-width: 400px) {
    .about-container {
        .title {
            font-size: 25px !important;
        }
        .subtitle {
            font-size: 22px !important;
        }
        .text {
            font-size: 15px !important;
        }
    }
}

.about-container {
    width: 100%;
    .title {
        @include title;
    }
    .subtitle {
        @include subtitle;
        font-size: 36px;
    }
    .text {
        @include text2;
        font-size: 17px;
    }
    .about-row {
        min-height: 550px;
        padding: 0;
        margin-bottom: 120px;
        .about-col {
            position: relative;
            width: 100%;
            display: flex;
            padding-left: 0;
            flex-direction: column;
            justify-content: center;
            background-color: rgba(213, 239, 241, 0.656);
            .bg-image {
                object-fit: cover;
                position: absolute;
                padding: 0;
                height: 100%; 
                width: 100%;
                transition: 1s;
            }
            .title {
                z-index: 1;
                height: fit-content;
                font-size: 45px;
                margin: 10% 0 5% 15%;
                max-width: 1000px;
                padding: 0;
            }
            .text {
                @include text1;
                z-index: 1;
                height: fit-content;
                margin: 0;
                margin-left: 15%;
                max-width: 680px;
                padding: 0;
                padding-bottom: 5%;
            }
        }
    }
    .mission, .vision {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 300px;
        padding-bottom: 90px;
        .mission-image {
            width: 140px;
        }
        .subtitle {
            max-width: 650px;
            width: 95%;
            text-align: center;
            margin: 2% 0;
        } 
        .text {
            max-width: 520px;
            width: 95%;
            text-align: center;
        }
    }
    .vision {
        padding: 0 0 100px 0;
        .vision-image {
            width: 180px;
        }
        .subtitle {
            max-width: 820px;
            width: 95%;
        } 
        .text {
            max-width: 710px;
            width: 95%;
        }
    }

    .team {
        text-align: center;
        padding: 0 12% 100px 12%;
        .subtitle {
            padding: 2%;
            margin-bottom: 15px;
        }
        .team-card {
            .team-img {
                height: 200px;
                width: 200px;
                margin: auto;
                border-radius: 10px;
                box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.112);
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                }
            }
            .name {
                margin: 10px;
                font-size: 18px;
            }
            .position {
                color: rgb(127, 127, 127);
                font-size: 15px;
            }
        }
    }
    .readings {
        padding: 100px 10% 0 10%;
        .subtitle {
            text-align: center;
            padding: 2%;
            margin-bottom: 15px;
        }
        .col {
            display: flex;
            justify-content: center;
            .readings-post {
                padding: 20px;
                padding-bottom: 30px;
                border-radius: 10px;
                box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.112);
                background-color: white;
                width: 340px;
                margin-bottom: 20px;
                // min-height: 150px;
                .reading-type {
                    @include text2;
                    font-size: 17px;
                    margin: 0;
                    margin-bottom: 15px;
                }
                .reading-title {
                    @include text2;
                    letter-spacing: 2px;
                    font-size: 17px;
                    font-weight: bold;
                    color: $primary;
                    margin: 10px 0;
                }
                .reading-text {
                    @include text3;
                    font-size: 15px;
                    margin: 0;
                }
            }
        }
    }

    .contact-row {
        padding: 0 15% 100px 15%;
        .contact-col {
            padding: 0;
            text-align: center;
            .subtitle {
                padding: 4% 0;
            }
            p {
                @include text3;
                font-size: 14px;
                margin: 0;
                a {
                    color: $primary;
                    &:hover {
                        color: $orange;
                    }
                }
            }

        }

    }
}

.about-container .animated-row {
    opacity: 0.1;
    position: relative;
    bottom: -120px;
    transition: transform 0.5s, opacity 0.8s ;
}
