@import "../../styles/importStyles.scss";

.not_found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 60px);
    padding: 0 5%;
    .not_found_icon {
        height: 32vh;
        margin: 1% 0;
    }
    h1 {
        @include title;
        margin: 1.5% 0;
    }
    p {
        @include heading2;
        margin: 0;
        text-align: center;
    }
    .go_home {
        text-decoration: none;
        @include button1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3% 0 1% 0;
    }
}