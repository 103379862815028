@import "../../styles/importStyles.scss";

.post-job-container {
    width: 80%;
    .need-tips {
        max-width: 600px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .back {
            @include shortBtn2;
        }
    }
    .form {
        max-width: 600px
    }
    .title {
        max-width: 600px
    }
    .heading {
        font-variant: normal;
        text-transform: none;
        letter-spacing: normal;
    }
    .job_form_title {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 620px;
        .icon {
            font-size: 38px;
            margin-top: 30px;
            margin-bottom: .5rem;
            color: $primary;
            &:hover {
                cursor: pointer;
                color: $orange;
            }
        }
    }

    .union-specific-radio {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        margin: 5px 0 15px 0;
        .option {
            input {
                margin-right: 10px;
            }
        }

    }

    .project_input_autocomplete, .cityInput, .roleInput {
        ::-webkit-input-placeholder {
            color: black;
            opacity: 0.65;
        }
        :-moz-placeholder {
            color: black;
           opacity: 0.65;
        }
        ::-moz-placeholder {
            color: black;
           opacity: 0.65;
        }
        :-ms-input-placeholder {
            color: black;
            opacity: 0.65;
        }
        ::-ms-input-placeholder { 
            color: black;
            opacity: 0.65;
        }
        ::placeholder {
           color: black;
           opacity: 0.65;
        }
        input {
            @include text2;
            font-size: 16px !important;
            padding: 0 0 0 8px;
            height: 19px !important;
        }
    }
    .project_inputs {
        .choose_project {
            display: flex;
            align-items: center;
            #yes,#no {
                margin: 10px;
            }
            label {
                width: 35px;
            }
            .project_input {
                @include borderStyle;
                width: 100%;
                height: 37.7px;
                margin: 10px;
                margin-right: 0;
                &[disabled] {
                    opacity: 0.6;
                }
            }
            .project_input_autocomplete {
                width: 100%;
                margin: 10px;
                margin-right: 0;
                height: 37.7px;
                fieldset {
                    border: none;
                    height: 37.7px;
                }
                input {
                    border: 2px solid $borders;
                    border-radius: 5px;
                }
                div div div {
                    background-color: $inputs;
                    padding: 0;
                }
            }
        }
    }

    input, textarea {
        padding: 8px;
        outline: none;
    }

    .inputs {
        .cityInput {
            width: 100%;
            margin: 0 !important;
        }
        .categoryInput {
            width: 50%;
        }
        
    }
    

    .post-job-input {
        width: 100%;
        height: 37.7px;
    }

    .roleInput {
        width: 100%;
        fieldset {
            border: none;
        }

        input {
            border-radius: 5px;
        }

        div div div {
            padding: 0;
            background-color: $inputs;
        }

        .MuiAutocomplete-popper div {
            padding-left: 8px;
            background-color: white;
        }

        .MuiOutlinedInput-root {
            border: 2px solid $borders;
        }   
    }

    .calendar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .calendar1, .calendar2 {
            width: 40%;
            input {
                width: 100%;
                height: 37.7px;
            }
        }
    }
    .requirements {
        background-color: $inputs;
        border-radius: 5px;
        border: 2px solid $borders;
        height: 200px;
    }
    
    .buttons {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
        .submit {
            padding: 0;
            @include button1;
            margin-left: 20px
        }
        .back {
            @include button2;
        }
    }
    
    input {
        @include text2;
    }
    
    .checkbox {
        width: 100%;
        div {
            margin-right: 15px; 
            margin-bottom: 6px; 
        }
       
    }
    .meta-data .checkbox {
        margin-bottom: 0;
    }

    .remote-checkbox {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        label {
            margin-right: 15px; 
        }
        .tip-icon {
            margin: 10px;
            &:hover {
                cursor: pointer;
            }
        }
       
    }

    .notifications {
        margin: 20px 0;
        display: flex;
        align-items: center;
        label {
            margin-left: 15px; 
        }
    }
}
