@import "../../styles/importStyles.scss";

.details {
    padding: 10%;
    .buttons {
        .apply {
            @include button1;
            margin: 10px 0;
        }
        .already-applied {
            @include button2;
            margin: 10px 0;
        }
        .back {
            color: $primary;
            margin: 15px 0;
            &:hover {
                cursor: pointer;
                color: $orange;
            }
        }
    }
}
.success-modal {
    .modal-dialog {
        max-width: 500px;
        .modal-body {
            @include text2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 5%;
            text-align: center;
            img {
                height: 45px;
                margin: 5px;
                margin-top: 25px;
            }
            .modal-heading {
                color: $blue;
                @include heading2;
                font-weight: 600;
                margin-bottom: 30px;
            }
            .contact_info {
                text-align: left;
                font-size: 18px;
                list-style: none;
                margin: 15px 0;
                padding: 0;
                li {
                    color: $blue;
                }
            }
            p {
                margin: 0;
                a {
                    color: $primary;
                }
                .email {
                    color: $primary;
                }
            }
            .update_contact {
                margin: 20px 0;
            }
            .closeButton {
                position: absolute;
                top: 10px;
                right: 10px;
                color: $primary;
                font-size: 25px;
                &:hover {
                    cursor: pointer;
                }
            }
            .buttons {
                margin-top: 20px;
                .cancel {
                    @include shortBtn2;
                    margin: 5px;
                }
                .close {
                    @include shortBtn1;
                    margin: 5px;
                }
            }
            .upgrade {
                @include shortBtn1;
                margin: 30px;
                width: fit-content;
            }
            .sign-in {
                @include shortBtn1;
                margin: 30px;
            }
            
            .create-profile {
                margin: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .create-p-button {
                    @include button1;
                    margin: 30px;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .warning-icon {
                font-size: 50px;
                margin: 5px;
                margin-top: 25px;
                color: $blue;
            }
        }
    }

}

