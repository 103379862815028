@import "../../styles/importStyles.scss";

@media (max-width: 768px) {
    .table-titles {
        display: none !important;
    }
    .device {
        flex-wrap: wrap !important;
    }
}

.mfa {
    display: flex;
    align-items: flex-start;
    padding: 30px 0 20px 0; 
    border-top: 1px solid $borders;
    .mfa-switch {
        margin-top: 5px;
    }
    .mfa-label {
        margin: 0 10px !important;
        .enable {
            margin: 0;
            @include text2;
        }
        .explanation {
            margin: 0;
            @include text3;
            text-align: justify;
        }
    }
   
}

.devices-list {
    padding: 20px 5px;
    width: 100%;
    .devices-heading {
        @include heading2;
        font-weight: bold;
    }
    .forget {
        @include button1;
        width: fit-content;
        padding: 0 15px;
        float: right;
    }
    .devices-table {
        @include text3;
        .device {
            padding: 20px 0;
            .name {
                font-weight: bold;
                margin: 0;
            }
            .ip, .time {
                margin: 0;
            }
        }
    }
}

.verification-text {
    margin-top: 30px !important;
}
.code-input {
    padding: 8px;
    margin-top: 15px;
    height: 37.7px;
}