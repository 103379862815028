@import "../../styles/importStyles.scss";

.details {
    padding: 15% 5%;
    @include text2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        margin: 5px 0;
    }
    span {
        font-weight: bold;
    }
    h1 {
        @include title;
    }
    .buttons {
        margin: 20px 0;
        .close {
            @include shortBtn2;
            margin-right: 10px;
        }
        .edit {
            @include shortBtn1;
        }
    }
    .metadata {
        padding: 20px;
    }
}
.applicants {
    padding: 10% 0;
    display: flex;
    flex-direction: column;
    h5 {
        @include text2;
        margin-bottom: 15px;
    }
    .applicant {
        border: 1px solid $borders;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @include text3;
        margin: 2px 0;
        background-color: white;
        .applicant_name {
            color: $primary;
            width: 110px;
            &:hover {
                color: $orange;
                cursor: pointer;
            }
        }
        p {
            width: 110px;
        }
        .applied-on {
            width: 80px;
        }
        .invite {
            width: 120px;
            font-size: 12px;
            text-decoration: underline;
            color: $primary;
            &:hover {
                cursor: pointer;
                color: $orange;
            }
        }
        .contact {
            width: 145px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: underline;
            color: $primary;
            &:hover {
                cursor: pointer;
                color: $orange;
            }
        }
        .profile-pic {
            height: 45px;
            width: 45px;
            border-radius: 5px;
            margin: 5px;
            &:hover {
                opacity: 0.6;
            }
        }
        .contact-details {
            p {
                width: fit-content;
                font-size: 11px;
                margin: 0;
            }
        } 
    }
}
