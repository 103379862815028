
@import "../../styles/importStyles.scss";

.hire-crew-row {
    @include text1;
    .hire-crew-col {
        padding: 3%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            @include title;
            width: 100%;
            max-width: 600px;
        }
        .text {
            @include text3;
            margin: 0;
            width: 100%;
            max-width: 600px;
            span {
                color: $primary;
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                    color: $orange;
                }
            }
        }

        .form {
            width: 100%;
            max-width: 600px;
            display: flex;
            flex-direction: column;
            .heading {
                margin-top: 14px;
                margin-bottom: 7px;
                @include heading2
            }
            .select-position {

                .inputs {
                    display: flex;

                    label {
                        @include text3;
                    }

                    .categoryInput {
                        width: 40%;
                        fieldset {
                            border: none;
                        }

                        input {
                            border: 2px solid $borders;
                            border-radius: 5px;
                            @include text3;
                        }

                        div div div {
                            background-color: $inputs;
                            padding: 0;
                        }

                        label {
                            all: initial;
                            padding-bottom: 5px;
                            @include text3;
                        }
                    }
                    .inputContainer {
                        width: 60%;
                        padding: 0;
                        margin-left: 10px;
                        .inputWrapper {
                            min-height: 37.7px;
                            width: 100%;
                            padding: 0;
                            border: 2px solid $borders;
                            background-color: $inputs;
                            overflow-y: auto;

                            input {
                                background-color: $inputs;
                                height: 100%
                            }
                        }
                        label {
                            padding: 0;
                        }
                    }
                }

                .roles-error {
                    @include text2;
                    width: 100%;
                    text-align: end;
                    padding-right: 20px;
                }

            }
            .select-location {
                .inputs {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;

                    .find-crew-city-input{
                        width: 82%;
                    }
                    .cityInput {
                        margin: 5px;
                        margin-left: 0;
                        fieldset {
                            border: none;
                        }
                        input {
                            border: 2px solid $borders;
                            border-radius: 5px;
                            @include text3;
                        }
                        div div div {
                            background-color: $inputs;
                            padding: 0;
                        }
                        label {
                            all: initial;
                            padding-bottom: 5px;
                            @include text3;
                        }
                    }
                    .radiusInput {
                        display: flex;
                        flex-direction: column;
                        width: 15%;
                        min-width: 70px;
                        label {
                            @include text3;
                            padding-bottom: 2px;
                        }
                        #radius {
                            height: 38px;
                            background-color: $inputs;
                            border: 2px solid $borders;
                            border-radius: 5px;
                            @include text3;
                            outline: none;
                        }
                    }

                }
            }
            .crew-name {
                display: flex;
                flex-direction: column;
                margin: 0;
                width: 100%;
                input {
                    height: 37.7px;
                    background-color: $inputs;
                    border: 2px solid $borders;
                    border-radius: 5px;
                    @include text2;
                    padding: 8px;
                }
            }
        }

        .meta-data {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .checkbox {
            div {
                display: flex;
                align-items: center;
            }
            margin: 5px;
            label {
                margin-left: 10px;
            }
            @include heading2;
        }

        .availability {
            .calendar {
                width: 100%; 
                display: flex;
                align-items: center;
                justify-content: space-between;
                .calendar1, .calendar2 {
                    width: 45%;
                    input {
                        height: 37.7px;
                        width: 100%;
                        @include text2;
                        padding: 8px;
                    }
                }
            }
            
        }

        .search {
            @include button1;
            margin: 15px;
            align-self: center;
        }
    }
}

.tips {
    .modal-body {
        padding-top: 8% !important;
    }
}