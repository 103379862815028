@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Work+Sans:wght@400&display=swap');

@mixin title {
    font-size: 37px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
}

@mixin subtitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    letter-spacing: 2px;
}
@mixin heading1 {
    font-family: 'Work Sans', sans-serif;
    font-size: 27px;
}
@mixin heading2 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
}
@mixin text1 {
    font-family: 'Work Sans', sans-serif;
    font-size: 18px;
}
@mixin text2 {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
}
@mixin text3 {
    font-family: 'Work Sans', sans-serif;
    font-size: 13px;
}

