@import "../../styles/importStyles.scss";

@media (max-width: 992px) {
    .job-postings {
        .titles {
            display: none !important;
        }
    }
}


.job-container {
    h1 {
        @include title;
        margin-top: 5%;
    }
    .post-job-btn {
        @include button1;
        margin: 3% 2%;
    }
    .job-postings {
        @include text2;
        font-size: 15px;
        .titles {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            font-weight: bold;
            .union {
                width: 30px;
                margin: 0;
            }
            p {
                width: 155px;
            }
            .buttons_column {
                min-width: 180px;
            }
        }
        .job-posting {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            border-bottom: 1px solid $borders;
            .small_union_logo {
                width: 30px;
            }
            .production_title {
                text-decoration: underline;
                color: $primary;
                &:hover {
                    cursor: pointer;
                    color: $orange;
                }
            }
            .buttons {
                display: flex;
                align-items: center;
                .view-button {
                    @include shortBtn2;
                    margin: 5px;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 15px;
                    width: 80px;
                }
                .close-button {
                    margin: 5px;
                    @include shortBtn1;
                    font-size: 15px;
                    width: 80px;
                }
            }
            
            p {
                width: 155px;
            }
        }
    }

    .nav-link {
        @include text2;
        color: $primary;
        background-color: $secondary;
        opacity: 0.5;
        &.active {
            background-color: $secondary;
            color: $primary;
            opacity: 1;
        }
    }

    .tab-div {
        .tab_autocomplete {
            margin: 20px 0;
            max-width: 300px;
            @include text2;
            fieldset {
                border: none;
            }
    
            input {
                border-radius: 5px;
            }
            div div div {
                padding: 0;
                background-color: $inputs;
            }    
            .MuiAutocomplete-popper div {
                padding-left: 8px;
                background-color: white;
            }   
            .MuiOutlinedInput-root {
                border: 2px solid $borders;
            }   
        }
        .small-loader {
            margin: 5% 50%;
            color: $primary;
        } 
    }
    
}