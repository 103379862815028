@import "../../styles/importStyles.scss";

@media (max-width: 450px) {
    .react-datepicker__month-container {
        width: 220px !important;
    }
    .react-datepicker__navigation--previous{
        left: 5px !important;
    }
    .react-datepicker__navigation--next{
        right: 5px !important;
    }
}

.calendar{
    .react-datepicker {
        padding: 10px;
        .react-datepicker__navigation {
            top: 20px;
            .react-datepicker__navigation-icon::before {
                height: 14px;
                width: 14px;
                border-width: 3px 3px 0 0;
                border-color: #9b2915;
            }
        
        } 
        .react-datepicker__navigation--previous{
            left: 70px;
        }
        .react-datepicker__navigation--next{
            right: 70px;
        }
        .react-datepicker__month-container {
            width: 350px;
            height: 320px;
            .react-datepicker__header {
                background-color: white;
                border-bottom: none;
                padding: 0;
                .react-datepicker__current-month {
                    padding: 10px;
                    @include heading2;
                    color: $dark;
                }
                .react-datepicker__day-names{
                    display: flex;
                    justify-content: space-around;
                    .react-datepicker__day-name{
                        margin: 6px 0;
                        @include text1;
                        color: $dark;
                        text-transform: uppercase;
                        font-size: smaller;
                    }
                }
            }
            .react-datepicker__month {
                .react-datepicker__week {
                    display: flex;
                    justify-content: space-around;
                    .react-datepicker__day {
                        margin: 6px 0;
                        z-index: 1;
                    }
                    .react-datepicker__day--in-selecting-range {
                        background-color: transparent;
                        color: #212121;
                        position: relative;
                        &::before {
                            position: absolute;
                            content: "";
                            z-index: -1;
                            height: 30px;
                            width: 50px;
                            right: -8px;
                            background-color: #fff1e3;
                        }
                    }
                    .react-datepicker__day--in-range {
                        background-color: transparent;
                        color: #212121;
                        position: relative;
                        &::before {
                            position: absolute;
                            content: "";
                            z-index: -1;
                            height: 30px;
                            width: 50px;
                            right: -8px;
                            background-color: #fff1e3;
                        }
                    }
                    .react-datepicker__day--selected {
                        background-color: transparent;
                        position: relative;
                        &::before {
                            position: absolute;
                            content: "";
                            z-index: -1;
                            height: 30px;
                            width: 20px;
                            right: -8px;
                            background-color: #fff1e3;
                        }
                        &::after {
                            position: absolute;
                            content: "";
                            z-index: -1;
                            height: 30px;
                            width: 30px;
                            right: -2px;
                            border-radius: 50%;
                            background-color: #fcd2a5;
                        }
                        -webkit-touch-callout: none; /* iOS Safari */
                        -webkit-user-select: none; /* Safari */
                        -khtml-user-select: none; /* Konqueror HTML */
                        -moz-user-select: none; /* Old versions of Firefox */
                        -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none;
                    }
                    .react-datepicker__day--keyboard-selected {
                        background-color: transparent;
                        position: relative;
                        &::before {
                            position: absolute;
                            content: "";
                            z-index: -1;
                            height: 30px;
                            width: 30px;
                            right: 20px;
                            background-color: #fff1e3;
                        }
                        &::after {
                            position: absolute;
                            content: "";
                            z-index: -1;
                            height: 30px;
                            width: 30px;
                            left: -2px;
                            border-radius: 50%;
                            background-color: #fcd2a5;
                        }
                        -webkit-touch-callout: none; /* iOS Safari */
                        -webkit-user-select: none; /* Safari */
                        -khtml-user-select: none; /* Konqueror HTML */
                        -moz-user-select: none; /* Old versions of Firefox */
                        -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none;
                    }
                    .react-datepicker__day--disabled {
                        color: $orange;
                        position: relative;
                        &::before {
                            position: absolute;
                            top: 12px;
                            left: 6px;
                            content: "";
                            width: 15px;
                            height: 1px;
                            background-color: $orange;

                        }
                    }
                    .react-datepicker__day--highlighted {
                        color: $orange;
                        position: relative;
                        background-color: $inputs;
                    }
                }
            }
        }
        
    }
    .edit {
        @include shortBtn1;
        margin: 10px;
    }
    .radio {
        margin-top: 10px;
        margin-right: 10px;
        width: 170px;
        @include text3;
        display: flex;
        align-items: center;
        input {
            margin-right: 5px;
        }
    }
    .buttons {
        margin-top: 20px;
        margin-right: 10px;
        .save {
            @include shortBtn1;
        }
        .save[disabled] {
            background-color: #c19090
        }
        .clear {
            @include shortBtn2;
            margin-right: 10px;
        }
    }
}

.calendar-profile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
