@import "../../styles/importStyles.scss";

.enable_emails {
    display: flex;
    align-items: flex-start;
    padding: 30px 0; 
    border-bottom: 1px solid $borders;
    .mfa-switch {
        margin-top: 5px;
    }
    .mfa-label {
        margin: 0 10px !important;
        .enable {
            margin: 0;
            @include text2;
        }
        .explanation {
            margin: 0;
            @include text3;
            text-align: justify;
        }
    }
   
}