@import "../../styles/importStyles.scss";

.project_invitations_container {

    @media (max-width: 700px) {
        padding: 5% !important;
    }

    @include text2;
    padding: 5% 18%;
    .title {
        @include title;
        margin-bottom: 30px;
    }
    .invitations {
        box-shadow: 0 0 5px 1px rgba(96, 96, 96, 0.081);
        border: 2px solid $borders;
        border-radius: 10px;
        padding: 10px 20px 25px 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
        .project_invitation {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 6px 10px;
            border-bottom: 1px solid $borders;
            .column1 {
                margin-right: 10px;
                p {
                    margin: 10px 0;
                }
                .link_to_project {
                    text-decoration: underline;
                    color: $primary;
                    &:hover{
                        color: $orange;
                        cursor: pointer;
                    }
                }
                .expiration_date {
                    font-size: 11px;
                    font-weight: bold;
                }
                .invitation_buttons {
                    display: flex;
                    .accept_invitation {
                        @include shortBtn1;
                        font-size: 16px;
                        width: 100px; 
                        margin-right: 10px;
                    }
                    .reject_invitation {
                        @include shortBtn2;
                        font-size: 16px;
                        width: 100px;
                    }
                }
            }
            .column2 {
                width: fit-content;
                p {
                    margin: 10px 0;
                    @include text3;
                }
            }
        }
    }
}