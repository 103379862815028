@import "../../styles/importStyles.scss";

.create_project {
    .modal-dialog {
        max-width: 95%;
        width: 500px;
    }
    .input_username {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        .title {
            @include heading1;
            font-size: 22px;
            color: $blue;
        }
        input {
            @include borderStyle;
            width: 100%;
            margin-top: 20px;
        }
        .buttons {
            width: 100%;
            text-align: right;
        }
    }
    .create_project_form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        .create_title {
            margin: 10px;
            height: 38px;
            width: 100%;
            padding: 8px;
        }
        textarea {
            @include borderStyle;
            margin: 10px;
            height: 100px;
            width: 100%;
        }
        .create_button {
            @include button1;
            margin: 10px;
        }
        .create_producers {
            @include borderStyle;
            @include text2;
            height: 38px;
            width: 100%;
        }
        .create_location {
            width: 100%;
            margin: 10px 0;
            margin-bottom: 10px;
            height: 38px;
            border: 2px solid $borders;
            border-radius: 5px;
            ::-webkit-input-placeholder {
                color: black;
                opacity: 0.65;
            }
            :-moz-placeholder {
                color: black;
               opacity: 0.65;
            }
            ::-moz-placeholder {
                color: black;
               opacity: 0.65;
            }
            :-ms-input-placeholder {
                color: black;
                opacity: 0.65;
            }
            ::-ms-input-placeholder { 
                color: black;
                opacity: 0.65;
            }
            ::placeholder {
               color: black;
               opacity: 0.65;
            }
            fieldset {
                border: none;
            }
            input {
                height: 19px;
                width: 100%;
                @include text2;
            }
            div div div {
                background-color: $inputs;
                padding: 0;
            }
            label {
                all: initial;
                padding-bottom: 5px;
                @include text2;
            }
        }
        .create_dates {
            text-align: left;
            label {
                @include text2;
            }
            width: 100%;
            .calendar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include text2;
                .calendar1, .calendar2 {
                    max-width: 200px;
                }
                input {
                    height: 38px;
                    padding: 8px;
                    width: 100%;
                }
            }
        }
        .create_description {
            @include borderStyle;
            height: 100px;
        }
    }
}