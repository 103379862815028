@import "../../styles/importStyles.scss";

@media (max-width: 400px) {
    .footer {
        padding: 0 !important;
    }
}

.footer {

    background-color: $primary;
    min-height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1% 14% 2% 14%;
    z-index: 1;
    .copyrights {
        margin: 20px;
       .footer-content {
            width: fit-content;
            @include text2;
            color: white;
            text-decoration: none;
            margin-bottom: 0;
        }
        .about-link {
            color: white;
            font-style: italic;
            @include text3;
        }
        .policy-terms {
            @include text3;
            color: white;
            margin: 0;
            margin-top: 15px;
            width: 220px;
            display: flex;
            justify-content: space-between;
            a {
                @include text3;
            }
        } 
        .socialmedia {
             img {
                height: 22px;
                margin: 0 25px 15px 0;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
    .join-now {
        max-width: 300px;
        margin: 20px;
        .heading {
            @include text2;
            color: white;
            margin-right: 20px;
        }
        .signup {
            @include shortBtn1;
            height: 40px;
            background-color: $secondary;
            color: $primary;
            margin-top: 20px;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .quick-links {
        display: flex;
        flex-direction: column;
        // margin-top: 20px;
        margin: 20px;
        .heading {
            @include text2;
            color: white;
        }
        .search-link {
            @include text3;
            color: white;
        }
    }

}