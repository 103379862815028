@import "../../styles/importStyles.scss";

.auth-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    @include text2;
    margin: 5%;

    [data-amplify-router] {
        border: none;
        box-shadow: none;
        background-color: transparent;
    }

    .amplify-image {
        height: 100px;
    }

    .amplify-checkbox__button {
        width: 1em;
        margin: 20px 0;
    }

    .amplify-tabs-item {
        color: $dark;
        @include text2;
        &:hover {
            color: $primary;
        }
    }

    .amplify-tabs-item[data-state=active] {
        border-color: $primary;
        color: $primary;
    }
    .amplify-icon {
        width: 2em !important;
        display: flex;
        justify-content: center;
    }

    .amplify-button {
        @include text2; 
    }

    .amplify-button[data-variation=primary] {
        @include button1;
        width: 100%;
    }

    .amplify-button[data-loading=true] {
        @include text2;
        color: white;
        span, p {
            color: white;
        }
    }

    .amplify-button[data-size=small] {
        color: $primary;
        @include text2;
        font-size: 14px;
        &:hover {
            background-color: $secondary;
        }
    }

    .amplify-input {
        background-color: $inputs;
        @include text2;
    }

    .amplify-button__loader-wrapper {
        @include text2;
    }

    .amplify-heading {
        @include text1;
        font-weight: 600;
    }

    .amplify-text {
        @include text2;
    }

    .amplify-alert__body {
        @include text3;
    }

    [data-amplify-authenticator-confirmsignup] .amplify-authenticator__subtitle {
        display: none;
    }

    .terms-checkbox {
        position: relative;
        .checkbox-label {
            position: absolute;
            top: 18px;
            left: 30px;
            @include text2;
            .link-to-terms {
                color: $primary;
                @include text2;
            }
        }
    }

    .password_requirements {
        @include text3;
        font-size: 11px;
        background-color: $inputs;
        padding: 10px;
        padding-left: 30px;
        margin: 5px 30px 0;
        border-radius: 10px;
    }

    .amplify-passwordfield {
        .amplify-label {
            @include text3;
            font-size: 11px;
            color: $orange;
            margin-left: 5px;
            margin-bottom: 10px;
            margin-top: -10px;
        }
    }
    .password_container {
        position: relative;
        #password_popper {
            position: absolute;
            display: none;
            @include borderStyle;
            padding: 20px;
            height: fit-content;
            z-index: 2;
            width: 70%;
            @include text3;
            ul {
                list-style: none;
                padding-left: 20px;
            }
            .valid {
                color: green;
            }
            
            .valid:before {
                position: relative;
                left: -10px;
                content: "✔";
            }

            .invalid {
                color: red;
            }
            
            .invalid:before {
                position: relative;
                left: -10px;
                content: "✖";
            }
        }

        #password_confirmation_popper {
            position: absolute;
            @include borderStyle; 
            padding: 20px;
            height: fit-content;
            z-index: 1;
            width: 60%;
            @include text3;
            p {
                margin: 0;
                color: red;
            }
        }
        .show_password {
            position: absolute;
            right: 0;
            height: 42px;
            width: 66px;
            padding: 1em;
            border-radius: 0 4px 4px 0;
            background-color: rgba(255, 255, 255, 0.6);
        }
        
    
        .open {
            display: block !important;
        }
    }
}