@import '../../styles/importStyles.scss';

.subscribe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        @include heading2;
    }
    .click-here {
        color: $primary;
    }
}
.send_message_and_email {
    .modal-dialog {
        max-width: 95% !important;
        width: 600px;
        .close_icon {
            color: $primary;
            position: absolute;
            top: 15px;
            right: 15px;
            &:hover {
                cursor: pointer;
                color: $orange;
            }
        }
        .modalform {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            p {
                @include text3;
                padding: 5%;
            }
            input {
                height: 37.7px;
            }
            textarea {
                height: 200px;
                outline: none;
            };
            input, textarea {
                padding: 8px;
                width: 80%;
                background-color: $inputs;
                border: 2px solid $borders;
                border-radius: 5px;
                @include text2;
                margin: 5px;
            }
            .checkbox {
                display: flex;
                align-items: center;
                input {
                    width: fit-content
                }
            }
            .submitButton {
                @include button1;
                padding: 0;
            }
        }
    }
}