@import "../../styles/importStyles.scss";

@media (max-width: 992px) {
    .my-jobs-div {
        .titles {
            display: none !important;
        }
    }
}

.my-jobs-container {
    padding: 5% 10%;
    .title {
        @include title;
        margin-bottom: 30px;
    } 
    .my-jobs-div {
        box-shadow: 0 0 5px 1px rgba(96, 96, 96, 0.081);
        border: 2px solid $borders;
        border-radius: 10px;
        padding: 10px 20px 25px 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
        .titles {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 15px 15px 0 15px;
            font-weight: bold;
            @include text2;
            p {
                margin: 0;
                width: 130px;
            }
            .applied_on, .status {
                width: 100px;
            }
            .see_job_column {
                min-width: 105px;
            }
        }
        .no-jobs {
            align-self: center;
            padding: 30px 0;
            @include heading2;
        } 
        .my-jobs {
            
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            margin: 5px 0;
            border-bottom: 1px solid $borders;
            min-height: 80px;
            padding: 15px;
            flex-wrap: wrap;
            @include text3;

            h5 {
                @include text3;
                width: 130px;
                margin: 15px 0;
            }
            .prod-title {
                font-weight: bold;
                color: $primary;
            }
    
            .location {
                margin: 0;
                display: flex;
                align-items: center;
                width: 130px;
                font-size: 12px;
                img {
                    height: 15px;
                    margin-right: 5px;
                }
            }
            .created_at,.status {
                width: 100px;
                margin: 15px 0;
            }
            .see-job {
                @include shortBtn2;
                width: 105px;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                margin: 5px 0;
            }  
            .details_space {
                width: 105px;
            }
        }
    }
}