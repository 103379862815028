@import "../../styles/importStyles.scss";

.over-limits-modal {
    @include heading2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
        @include button2;
    }
}