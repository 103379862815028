@import "../../styles/importStyles.scss";

.plan-selection-container {
    padding: 0 11% 4% 11%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .first-row {
        margin: 4% 0 2% 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        h1 {
            @include title;
            padding: 0;
            max-width: 1020px;
        }
        p {
            @include text1;
            padding: 0;
            margin: 0;
            max-width: 1020px;
        }
    }
    .plan-selection-row {
        display: flex;
        justify-content: center;
        .freemium {
            ul {
                margin-bottom: 50px;
            }
        }
        .freemium, .indie-surfers, .established-pros, .busy-bees {
            height: 420px;
            width: 240px;
            border: 2px solid $primary;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            margin: 10px;
            h5 {
                @include subtitle;
                font-size: 12px;
                letter-spacing: 2px;
                margin: 5px;
            }
            h6 {
                @include text3;
                font-size: 14px;
                margin-bottom: 20px;
            }
            .image {
                height: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    transform: scale(0.9);
                }
            }
            ul {
                list-style: none;
                padding: 0;
                @include text3;
                font-size: 12px;
                li {
                    display: flex;
                    align-items: flex-start;
                    p {
                       margin: 2px 0 2px 5px; 
                    }
                    
                    img {
                        height: 8px;
                        margin: 8px 0;
                    }
                }
            }
            button {
                @include shortBtn1;
                width: 140px;
                font-size: 15px;
            }
        }
    }
    .why-upgrade-row {
        width: 100%;
        max-width: 1020px;
        .subtitle {
            @include subtitle;
            padding: 4% 0 2% 0;
        }
        .text {
            @include text2;
            margin: 0 0 10px 0;
            padding: 0;
            a {
                color: $primary;
            }
        }
    }
}