@import "../../styles/importStyles.scss";

.navbar {
    background-color: $navbar;
    padding: 2px;
    position: sticky;
    top: 0;
    z-index: 5;
    transition: 0.5s;
    .signin-button {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        margin-right: 40px !important;
    }
    .nav-link {
        margin: 2px 15px;
        @include heading2;
        font-size: 17px;
        color: $primary !important;
        &:hover {
            cursor: pointer;
            color: $orange !important;
        }
    }
    .new_message, .new_invitation {
        display: flex;
        div {
            position: relative;
            top: 3px;
            right: 3px;
            height: 7px;
            width: 7px;
            border-radius: 50%;
            color: transparent;
        }
        .unread_messages {
            background-color: $primary;
            transition: 0.2s;
        }
        &:hover {
            cursor: pointer;
            .messages_icon {
                color: $orange;
            }
            .unread_messages {
                background-color: $orange;
            }
        }
    }
    .notifications_icon, .messages_icon {
        font-size: 24px;
        margin-top: 2px;
        margin-left: 6px;
        color: $primary;
        transition: 0.2s;
        &:hover {
            cursor: pointer;
            color: $orange;

        }
    }
    .messages_icon {
        font-size: 22px;
        font-weight: bold;
    }
    .collapsed_new_message {
        margin-left: 80px;
    }
    .user-links {
        display: flex;
        align-items: center;
        .profile-icons {
            margin: 10px 40px 10px 30px;
            display: flex;
            align-items: center;
            .profile-nav {
                font-size: 22px;
                color: $primary;
                transition: 0.2s;
            }
            p {
                margin: 0 0 0 10px;
                @include text2;
                font-size: 17px;
                color: $primary;
                transition: 0.2s;
            }
            &:hover {
                cursor: pointer;
                p, .profile-nav {
                    color: $orange;
                }    
            }
        }
    }
    .logo {
        margin: 0;
        padding: 0;
        width: 15%;
        display: flex;
        align-items: center;
        img {
            margin: 10px 30px;
            height: 35px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .active-page {
        color: #777777 !important;
    }
    .collapsed_signin {
        margin-left: 43%;
    }
    .navbar-toggler {
        border: none;
        color: transparent;
        img {
            height: 20px;
        }
    }
}



.profile-settings {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include text2;
    color: $primary;
    background-color: #efefef;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 20px;
    padding-right: 40px;
    top: 18px !important;
    left: -10px !important;
    width: fit-content;
    z-index: 1;
    .my-profile {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $primary;
        padding-left: 15px !important;
        img {
            height: 28px !important;
            width: 28px !important;
            border-radius: 50%;
            margin-right: 4px !important;
        }
    }

    p {
        margin: 5px;
    }
    .nav-link {
        padding: 0.5rem 1rem;
    }
    .popover-link {
        color: $primary;
        text-decoration: none;
        padding-left: 22px;
        display: flex;
        align-items: center;
        img {
            width: 18px;
            margin-right: 5px
        }
        .icon {
            font-size: 18px;
            margin-right: 5px;
            path {
                stroke: $primary;
            }
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
            cursor: pointer;
        }
    }

    .signout {
        margin: 50px 0 10px 0;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .reminder {
        font-size: 10px;
        margin-left: 42px;
        display:flex;
        color: $dark;
        .type {
            width: 100px;
        }
        .value {
            font-weight: bold;
        }
        .see-details {
            font-size: 12px;
            display: flex;
            align-items: center;
            .icon {
                font-size: 20px;
            }
            &:hover {
                cursor: pointer;
                color: $orange;
            }
        }
    }
    .current-plan {
        font-weight: bold;
        font-size: 12px;
    }
}