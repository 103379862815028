@import "../../styles/importStyles.scss";

.messages_container {
    @media (max-width: 800px) {
        padding-top: 1% !important;
        .title {
            display: none;
        }
        .channels_preview {
            height: 30vh !important;
            width: 350px !important;
        }
        .chat_container {
            height: 50vh !important;
            margin: auto !important;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: $inputs;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(180, 180, 180, 0.5);
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(180, 180, 180, 0.7);
    } 

    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 60px);
    padding: 6% 5% 3% 5%;
    .title {
        @include title;
        position: absolute;
        top: 75px;
        left: 6%;
    }
    .channels_preview {
        height: 100%;
        max-height: 800px;
        width: 30%;
        min-width: 250px;
        margin: 0 auto;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        overflow-y: auto;
        border-radius: 5px;
        box-shadow: 0 0 5px 1px rgba(96, 96, 96, 0.1);
        @include text2;
        font-size: 15px;
        .channel + .channel {
            border-top: none;
        }
        .channel {
            position: relative;
            width: 100%;
            min-height: 90px;
            border: 1px solid $borders;
            border-radius: 5px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 10px;
            margin: 0;
            transition: 0.2s;
            &:hover {
                cursor: pointer;
                box-shadow: 0 0 5px 2px rgba(96, 96, 96, 0.1);
                font-size: 16px;
                background-color: white;
                .target_name {
                    color: $blue !important;
                }
                .last_message {
                    color: black !important;
                    font-size: 15.5px !important;
                }
                .target_picture {
                    transform: scale(1.07);
                }
                .message_time {
                    color: black !important;
                }
            }
            .channel_col_1 {
                display: flex;
                align-items: center;
                .target_picture {
                    transition: 0.2s;
                    margin-right: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        border-radius: 5px;
                        object-fit: cover;
                    }
                }
                .channel_content {
                    overflow: hidden;
                    height: 100%;
                    .target_name {
                        transition: color 0.2s;
                        color: rgb(124, 124, 124);
                        font-weight: bold;
                        margin: 0;
                    }
                    .last_message {
                        color: rgb(124, 124, 124);
                        margin: 0;
                        transition: 0.2s;
                        font-size: 14.5px;
                    }
                }
            }
            .channel_status {
                width: 30%;
                p {
                    margin: 0;
                }
                .message_time {
                    transition: 0.2s;
                    color: rgb(124, 124, 124);
                    @include text3;
                    font-size: 11px;
                }
                .unread_messages {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    color: $primary;
                    font-size: 20px;
                    margin: 15px 10px;
                }
            }
        }
        .active_channel {
            box-shadow: 0 0 5px 2px rgba(96, 96, 96, 0.1);
            font-size: 16px;
            background-color: white;
            border: 1px solid rgb(127, 127, 127) !important;
            .target_name {
                color: $blue !important;
            }
            .target_picture {
                transform: scale(1.07);
            }
            .last_message {
                color: black !important;
                font-size: 15.5px !important;
            }
            .message_time {
                color: black !important;
            }
        }
    }
    .chat_container {
        width: 65%;
        min-width: 350px;
        height: 100%;
        max-height: 800px;
        border-radius: 5px;
        border: 1px solid rgb(127, 127, 127);
        margin: 0 auto auto 0;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: end;
        box-shadow: 0 0 5px 2px rgba(96, 96, 96, 0.1);
    }

    .no_conversations {
        margin: auto;
        text-align: center;
        h2 {
            @include heading1;
        }
        p {
            @include text2;
        }
    }
}