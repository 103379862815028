@import "../../styles/importStyles.scss";

.ethics-form {
    @include text2;
    max-width: 600px;
    width: 100%;

    .header {
        @include text2;
    }

    .gender-selection, .pronouns-selection {
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 500px;
        margin-top: 40px;
        .gender-arrow {
            position: absolute;
            top: 39px;
            right: 2px;
            pointer-events: none;
            color: rgb(109, 109, 109);
        }
        #gender-select {
            -webkit-appearance:none;
            @include borderStyle;
            padding: 0 8px;
            height: 37.7px;
            width: 100%;
            outline: none;
            margin: 5px 0; 
            &:hover {
                cursor: pointer;
            }
        }
        margin-bottom: 40px;
    }

    .lgbtqia2 {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 50px;
        .lgbtqia2-option {
            display: flex;
            align-items: center;
            input {
                margin-right: 10px;
            }
            label {
                margin-right: 20px;
            }
        }
    }

    .ethnicity {
        margin-bottom: 50px;
        width: 100%;
       .ethnicity-option {
            display: flex;
            align-items: flex-start;
            margin: 10px 0;
            max-width: 500px;
            input {
                margin: 6px 10px 0 0 ;
            }
            label {
                span {
                    display: block;
                    @include text3;
                    text-align: justify;
                }
            }
       }
    }

    .buttons {
        margin: 20px 0;
        float: right;
        .close {
            @include shortBtn2;
            margin-right: 10px;
        }
        .submit {
            @include shortBtn1;
        }
    }

    .select-all {
        @include text3;
        color: gray;
        margin: 10px 0;
        text-align: justify;
    }
    .not-active {
        opacity: 0.7;
    }
}