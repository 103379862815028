@import "../../styles/importStyles.scss";

.edit-container {
    padding: 15% 5%;
    margin: 0;
    width: 100%;
    .hire-crew-col {
        padding: 0;
    }
    .subtitle {
        @include subtitle;
        width: 100%;
    }
    .checkbox {
        width: 100%;
        div {
            margin-right: 15px; 
            margin-top: 15px; 
        }
       
    }
}