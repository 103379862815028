@import "../../styles/importStyles.scss"; 

.chat_header {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    .channel_target {
        @include heading2;
        padding: 5px;
        margin: 0;
        margin-right: auto;
        display: flex;
        align-items: center;
    }
    .mute_channel {
        @include text2;
        display: flex;
        align-items: center;
        margin: 0 15px;
        .mute_label  {
            margin: 10px 0;
            .tip-icon {
                margin-right: 10px;
            }
        }
        .mute_switch {
            margin-left: 10px;
        }
    }
    .project_link {
        transition: 0.2s;
        color: $primary;
        text-decoration: none;
        &:hover {
            color: $orange;
            cursor: pointer;
        }
    }
}
.chat_title {
    @include heading2;
    margin: 10px;
    color: $blue;
}
.message_input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    @include text2;
    position: relative;
    .message_input_text {
        @include borderStyle;
        width: calc(100% - 50px);
        resize: none;
        transition: height 0.3s;
        &:focus {
            height: 60px;
        }
    }
    .send_icon {
        font-size: 25px;
        margin: 0 10px;
        color: $primary;
        &:hover {
            cursor: pointer;
            color: orange;
        }
    }
    .disabled {
        color:rgb(203, 161, 161);
        &:hover {
            cursor: default;
            color:rgb(203, 161, 161);
        }
    }
}
.messages {
    overflow-y: auto;
    margin-top: auto;
    @include text2;
    .error_message {
        display: flex;
        align-items: center;
        @include text3;
        padding: 10px;
        border: 1px solid $orange;
        border-radius: 5px;
        margin: 30px;
        margin-top: 10px;
        .warning-icon {
            color: $orange;
            font-size: 20px;
            margin-right: 10px;
        }
    }
    .load_more {
        width: 100%;
        margin: 0;
        padding: 10px 0;
        text-align: center;
        color: $primary;
    }
    .message {
        width: 100%;
        // min-width: 300px;
        padding: 5px 20px;
        display: flex;
        flex-direction: column;
        .message_sender_and_time {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 50px;
            margin-right: 5px;
            .message_time {
                font-size: 11px;
                margin: 0;
                color: rgb(160, 160, 160);
            }
            .sender_name {
                font-size: 15px;
                font-weight: bold;
                color: $primary;
                margin: 0;
            }
        }
        .message_div {
            display: flex;
            align-items: flex-start;
            .message_content {
                width: 100%;
                font-size: 15px;
            }
            img {
                height: 35px;
                width: 35px;
                object-fit: contain;
                border-radius: 6px;
                margin: 5px;
            }
        }
    }
    .sender_message {
        .message_content {
            border-radius: 10px;
            background-color: $secondary;
            min-height: 45px;
        } 
    }
    .receiver_message {
        .message_content {
            border-radius: 10px;
            background-color: $inputs;
            min-height: 45px;
        } 
    }
}