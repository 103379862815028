@import "../../styles/importStyles.scss";

.calendar2 {
    .react-datepicker__day--selected {
        &::before {
            width: 30px !important;
            right: 20px !important;
        }
        &::after {
            width: 30px !important;
            left: -2px !important;
        }
    } 
    .react-datepicker__day--keyboard-selected {
        &::before {
            width: 20px !important;
            left: 15px !important;
        }
    }
}
.calendar1 {
    .react-datepicker__day--keyboard-selected {
        &::before {
            width: 0 !important;
            left: 20px;
        }
    }
}

p {
    margin: 10px;
}

input {
    border-radius: 5px;
    border: 2px solid $borders;
    background-color: $inputs; 
    outline: none;
}